export function mapRuleGoToLogicFlowData(ruleGo) {
  const { metadata } = ruleGo;
  const nodes = [];
  const edges = [];

  //默认初始节点
  const startNode = {
    id: "start",
    type: "rule-go-node",
    x: ruleGo.ruleChain && ruleGo.ruleChain.additionalInfo ? ruleGo.ruleChain.additionalInfo.layoutX : 0,
    y: ruleGo.ruleChain && ruleGo.ruleChain.additionalInfo ? ruleGo.ruleChain.additionalInfo.layoutY : 0,
    text: "Input",
    properties: {
      ruleGoType: "start",
    },
  };
  nodes.push(startNode);

  //用户拖入节点
  const metaNodes = (metadata.nodes || []).map((node) => {
    return {
      id: node.id,
      type: "rule-go-node", //node.type,
      x: node.additionalInfo ? node.additionalInfo.layoutX : 0,
      y: node.additionalInfo ? node.additionalInfo.layoutY : 0,
      text: node.name,
      properties: {
        ruleGoType: node.type,
      },
    };
  });
  nodes.push(...metaNodes);

  //触发节点(开始节点)
  const endpoints = (metadata.endpoints || []).map((endpoint) => {
    return {
      id: endpoint.id,
      type: "rule-go-node",
      x: endpoint.additionalInfo ? endpoint.additionalInfo.layoutX : 0,
      y: endpoint.additionalInfo ? endpoint.additionalInfo.layoutY : 0,
      text: endpoint.name,
      properties: {
        ruleGoType: endpoint.type,
      },
    };
  });
  nodes.push(...endpoints);

  //连线
  const metaEdges = (metadata.connections || []).map((connection) => {
    return {
      type: "flow-link",
      text: connection.type,
      sourceNodeId: connection.fromId,
      targetNodeId: connection.toId,
    };
  });

  //默认输入节点到第一个节点的连线
  if(metaEdges[0]){
    edges.push({
      type: "flow-link",
      sourceNodeId: "start",
      targetNodeId: metaNodes[0].id,
    });
  }
  //节点之间的连线
  edges.push(...metaEdges);
  //router连线
  const routerEdges = [];
  for (const endpoint of metadata.endpoints || []) {
    for (const router of endpoint.routers) {
      routerEdges.push({
        type: "flow-link",
        text: router.from ? router.from.path : "",
        sourceNodeId: endpoint.id,
        targetNodeId: (router.to && router.to.path ? router.to.path : "").split(":")[1],
      });
    }
  }
  edges.push(...routerEdges);

  return {
    nodes,
    edges,
  };
}
