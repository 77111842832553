<template>
    <div class="rule-go-list">
        <div class="search-panel">
            <div></div>
            <div class="control-bar">
                <el-input
                    prefix-icon="el-icon-search"
                    v-model="keywords"
                    placeholder="keywords"
                    clearable
                    class="cereb-input list-view-search-input" />
                <el-button class="cereb-button" type="primary" @click="fetchTableData" icon="el-icon-refresh">
                    Refresh
                </el-button>
                <el-button class="cereb-button" @click="handleCreateJson" icon="el-icon-plus">
                    Create
                </el-button>
            </div>
        </div>
        <el-table :empty-text="'No Data'" :data="computedTable" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.3)" style="width: 100%"
            :header-cell-style="{ 'background': '#182130', 'height': '53px', 'border-bottom': '1px solid #252F40' }"
            :cell-style="{ 'background': '#182130', 'height': '44px', 'max-height': '44px', 'border-bottom': 'none' }">
            <el-table-column prop="ruleChain.id" label="ID" width="180" align="center"></el-table-column>
            <el-table-column prop="ruleChain.name" label="Name"></el-table-column>
            <el-table-column label="Operations" align="center" width="300">
                <template slot-scope="{row}">
                    <el-button @click="handleView(row)">View {{ row.id }}</el-button>
                    <el-button @click="handleEditJson(row)">Edit</el-button>
                    <el-button @click="handleDeleteJson(row)" type="danger">Delete</el-button>
                </template>
            </el-table-column>
        </el-table>
        <RuleGoJsonEditor v-model="showEdit" :id="editId" @input="fetchTableData" />
    </div>
</template>

<script>
import * as api from '@/api';
import RuleGoJsonEditor from './RuleGoJsonEditor.vue';
import { MessageBox } from 'element-ui';

export default {
    components: {
        RuleGoJsonEditor
    },
    data() {
        return {
            tableData: [],
            loading: false,
            showEdit: false,
            editId: null,
            editName: "",
            keywords: ""
        };
    },
    mounted() {
        this.fetchTableData();
    },
    methods: {
        async fetchTableData() {
            try {
                this.loading = true;
                let data = await api.getRules();
                this.tableData = data;
                this.loading = false;
            } catch (error) {
                this.$alert('Error fetching table data:', error, {
                    confirmButtonText: 'OK',
                    type: 'error'
                });
            }
        },
        handleView(row) {
            this.$emit("edit-rule-chain", row.ruleChain.id);
        },
        handleCreateJson() {
            this.editId = null;
            this.editName = "";
            this.showEdit = true;
        },
        handleEditJson(row) {
            this.editId = row.ruleChain.id;
            this.showEdit = true;
        },
        async handleDeleteJson(row) {
            await MessageBox.confirm('Are you sure you want to delete this rule?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            })
            await api.deleteRule(row.ruleChain.id)
            await this.fetchTableData()
        }
    },
    computed: {
        computedTable() {
            return this.tableData.filter(item => {
                return item.ruleChain.name.includes(this.keywords);
            });
        }
    }
};
</script>

<style>
.rule-go-list {
    display: block;
    margin: 25px 117px;
}

.el-table--border::after,
.el-table--group::after,
.el-table::before,
.el-table__empty-block {
    height: 0 !important;
    background-color: #182130;
}

.rule-go-list .el-table thead {
    color: #dddddd !important;
    font-weight: 400;
    width: auto;
}

.rule-go-list .el-table .cell {
    color: #cfd1d4 !important;
    padding-left: 0px;
    padding-right: 0px;
}


.rule-go-list .el-table__empty-block {
    background: #182130 !important;
}

.rule-go-list .search-panel {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.rule-go-list .control-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rule-go-list .list-view-search-input {
    margin-right: 10px;
}
</style>
