<template>
    <RuleGoDetail v-if="editId" :id="editId" />
    <RuleGoList v-else @edit-rule-chain="handleEdit" />
</template>

<script>
import RuleGoList from './rulego/RuleGoList.vue';
import RuleGoDetail from './rulego/RuleGoDetail.vue';
import { EventBus } from "@/event-bus.js"

export default {
    components: {
        RuleGoList,
        RuleGoDetail
    },
    data: function () {
        return { editId: null }
    },
    methods: {
        handleEdit(id) {
            console.log('Edit ID:', id);
            this.editId = id;
        }
    },
    mounted() {
        EventBus.$on('goBack', () => {
            this.editId = null;
        })
    }
}
</script>
