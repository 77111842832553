<template>
    <transition name="dialog-fade">
        <div v-show="show" class="dialog-overlay" @click="onClickModal">
            <div class="container" ref="containerRef">
                <header>Cereb Assistant
                    <img src="@/assets/ChatGPT/close.png" alt="" @click=onClose>
                </header>
                <iframe class="content" v-loading="loading" @load="handleLoaded" :src="iframeSrc"></iframe>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters } from 'vuex/dist/vuex.common.js';

export default {
    emits: ['input'],
    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
        }
    },
    computed: {
        ...mapGetters(['user']),
        iframeSrc() {
            const token = localStorage.getItem('token');
            const userId = this.user.id;
            // const url = `http://localhost:5173?_t=${Date.now()}/#/agent?token=${token}&userId=${userId}&agentId=cereb-assistant&focus=true&expand=false`;
            const url=`${process.env.VUE_APP_AGENT_API_URL_BASE}?_t=${Date.now()}/#/agent?token=${token}&userId=${userId}&agentId=cereb-assistant&focus=true&expand=false`;
            return url;
        },
    },
    methods: {
        onClickModal(event) {
            if (event.target.classList.contains('dialog-overlay')) {
                this.onClose();
            }
        },
        /**
         * 关闭对话
         */
        onClose() {
            this.$emit('update:show', false);
        },
        handleLoaded() {
            this.loading = false;
        },
    }
}
</script>

<style scoped>
.dialog-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 666666;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

/* 下面四个类是直接从 element-plus 搬过来的, 动画自己 diy 就行 */
.dialog-fade-enter-active {
    animation: dialog-fade-in 0.3s;
}

.dialog-fade-leave-active {
    animation: dialog-fade-out 0.3s;
}

@keyframes dialog-fade-in {
    0% {
        transform: translate3d(0, -20px, 0);
        opacity: 0;
    }

    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes dialog-fade-out {
    0% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    100% {
        transform: translate3d(0, -20px, 0);
        opacity: 0;
    }
}

.container {
    position: fixed;
    bottom: 42px;
    right: 109px;
    width: 375px;
    height: 706px;
    background: #212A3A;
    z-index: 10;
}

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 12px 0 18px;
    height: 53px;
    font-size: 17px;
    font-weight: 500;
    color: #FFFFFF;
    border-radius: 8px 8px 0px 0px;
}

header img {
    width: 27px;
    height: 27px;
}

.content {
    width: 100%;
    height: 653px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 0px 0px 8px 8px;
    border: none;
}

.content .message-list {
    /* 撑满剩余高度 */
    flex: 1;
    overflow-y: auto;
    padding: 36px 13px 0 13px;
    scroll-behavior: smooth;
}

.bottom-input {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px 0 8px;
    border-top: 1px solid #F0F0F0;
}

input {
    width: 277px;
    height: 38px;
    background-color: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #D2D2D2;
    margin-right: 10px;
    transition: all 0.2s ease-in-out;
}

input:focus {
    outline: none;
    border-color: #FCB900;
}

.submit-button {
    width: 56px;
    height: 38px;
    border-radius: 2px;
    background-color: #FCB900;
    opacity: 0.6;
    transition: all 0.2s ease-in-out;
}

input:focus~.submit-button {
    opacity: 1;
}

.submit-button img {
    width: 22px;
    height: 22px;
    margin: 8px 17px 8px 17px;
}
</style>
