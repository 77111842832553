import Vue from "vue";

import BaseNode from "./nodes/BaseNode";
import FunctionNode from "./nodes/FunctionNode";
import SwitchNode from "./nodes/SwitchNode";
import StartNode from "./nodes/StartNode";
import FetchNode from "./nodes/FetchNode";
import DelayNode from "./nodes/DelayNode";
import FlowLink from "./FlowLink";
import BackButton from "./tools/BackButton";
import VueHtmlNode from "./nodes/VueHtmlNode";
import RuleGoNode from "./nodes/RuleGoNode";

class LogicFlowNodeExtension {
  static pluginName = "LogicFlowNodeExtension";
  constructor({ lf }) {
    lf.register(BaseNode);
    lf.register(FunctionNode);
    lf.register(SwitchNode);
    lf.register(StartNode);
    lf.register(FetchNode);
    lf.register(FlowLink);
    lf.register(DelayNode);
    lf.register(VueHtmlNode);
    lf.register(RuleGoNode);
    lf.setDefaultEdgeType("flow-link");
    this.app = new Vue({
      render: (h) => h(BackButton),
      propsData: {
        lf: lf,
      },
    });
  }
  render(lf, domOverlay) {
    const node = document.createElement("div");
    node.className = "node-red-palette";
    domOverlay.appendChild(node);
    this.app.$mount(node);
  }
}

export default LogicFlowNodeExtension;
