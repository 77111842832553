<template>
    <div class="second-bar">
        <div class="second-bar-inside-box">
            <a class="menu-item" href="#" :class="{ selected: selectedTag === 'dashboard' }"
                @click="$emit('showMe', 'dashboard'), selected('dashboard')">
                <span>
                    Dashboard
                </span>
            </a>
            <a class="menu-item" href="#" :class="{ selected: selectedTag === 'assets' }"
                @click="$emit('showMe', 'assets'), selected('assets')">
                <span>
                    Assets
                </span>
            </a>
            <a class="menu-item" href="#" :class="{ selected: selectedTag === 'map' }"
                @click="$emit('showMe', 'map'), selected('map')">
                <span>
                    Map
                </span>
            </a>
            <a class="menu-item" href="#" :class="{ selected: selectedTag === 'connect' }"
                @click="$emit('showMe', 'connect'), selected('connect')">
                <span>
                    Connect
                </span>
            </a>
            <a class="menu-item" href="#" :class="{ selected: selectedTag === 'AI' }"
                @click="$emit('showMe', 'AI'), selected('AI')">
                <span>
                    A.I.
                </span>
            </a>
            <a class="menu-item" href="#" :class="{ selected: selectedTag === 'shadow' }"
                @click="$emit('showMe', 'shadow'), selected('shadow')">
                <span>
                    Shadow
                </span>
            </a>
            <a class="menu-item" href="#" :class="{ selected: selectedTag === 'flow' }"
                @click="$emit('showMe', 'flow'), selected('flow')">
                <span>
                    Flow
                </span>
            </a>
            <a v-if="user.plan === 'premium'" class="menu-item" href="#" :class="{ selected: selectedTag === 'rule-flow' }"
                @click="$emit('showMe', 'rule-flow'), selected('rule-flow')">
                <span>
                    RuleFlow
                </span>
            </a>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'HomeSecondBar',
    props: {
        msg: String,
    },
    emits: ['showMe'],
    computed: {
        ...mapGetters(['user'])
    },
    data() {
        return {
            lastSelectedTag: '',
            selectedTag: 'dashboard',
            plan: ''
        }
    },
    methods: {
        selected(currentTag) {
            // console.log(this.selectedTag)
            // console.log(currentTag)


            this.selectedTag = currentTag
            // console.log(this.selectedTag)

        },

        settingClick() {
            // this.onSettingClick();
            // this.$router.push({'name': 'setting'})
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* *********************************************************  second-bar  **************************************************************************** */

.second-bar {
    z-index: -1;
    margin-top: 66px;
    width: 100%;
    /* height: 56px; */
    background: #3C4556;
}

.second-bar-inside-box {
    z-index: 0;
    margin-left: 120px;
    margin-right: 120px;
    /* overflow-x: auto; */
    /* overflow-y: hidden; */
    box-sizing: border-box;
    /* width: 100vw; */
    display: flex;
    flex-wrap: wrap;
    height: 56px;
}

/*    .menu {
        height: 56px;
        color: #FCB900 !important;
    }*/

a {
    /* 不压缩 */
    flex-shrink: 0;
    text-decoration: none;
}

.menu-item {
    display: inline-block;
    z-index: 1;
    text-align: center;

    width: 130px;
    height: 56px;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    /*color: #FCB900;*/
    color: #FFFFFF;
    line-height: 56px;
    /*position: relative;*/
}

.selected {
    border-bottom: 2px solid #FCB900;
    color: #FCB900;
    box-sizing: border-box;
}

.assets {
    display: inline-block;

    z-index: 1;
    margin-left: 68px;

    width: 47px;
    height: 22px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 22px;
}

.connect {
    display: inline-block;

    z-index: 1;
    margin-left: 77px;

    width: 59px;
    height: 22px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 22px;
}

.flow {
    display: inline-block;

    z-index: 1;
    margin-left: 84px;

    width: 33px;
    height: 22px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 22px;
}
</style>