<template>
    <div class="rule-flow">
        <div class="lf-container" ref="containerRef"></div>
    </div>
</template>

<script>
import LogicFlow from '@logicflow/core'
// import { Control } from '@logicflow/extension'
import LogicFlowNodeExtension from '@/components/LogicFlowNode'
import '@logicflow/core/es/index.css'
import '@logicflow/extension/lib/style/index.css'
import '@/components/LogicFlowNode/style.css'
import { mapRuleGoToLogicFlowData } from '@/utils/logicFlowRuleGo'
import * as api from '@/api'

// 全局使用 每一个lf实例都具备 Control
// LogicFlow.use(Control)
LogicFlow.use(LogicFlowNodeExtension)

export default {
    props: {
        id: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            containerRef: null,
        }
    },
    mounted() {
        this.initializeLogicFlow()
    },
    methods: {
        async initializeLogicFlow() {
            if (!this.$refs.containerRef) return
            let rule = { metadata: {}, nodes: [], endpoints: [] }
            const id = this.id || 'chain_call_rest_api'
            if (id) {
                try {
                    rule = await api.getRuleById(id)
                }
                catch (error) {
                    this.$alert('fetch the single ruleChain error', error, {
                        confirmButtonText: 'OK',
                        type: 'error'
                    });
                }
            }
            const lf = new LogicFlow({
                container: this.$refs.containerRef,
                grid: {
                    size: 20,
                    visible: true,
                    type: "mesh",
                    config: {
                        color: "#2b2b2b",
                        thickness: 1
                    },
                },
                background: {
                    backgroundColor: '#120f18'
                },
            })
            const flowData = mapRuleGoToLogicFlowData(rule)
            lf.render(flowData)
            lf.translateCenter()
        }
    }
}
</script>

<style>
.rule-flow {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    overflow: hidden;
}

.rule-flow .lf-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
</style>
