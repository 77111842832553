<template>
    <el-dialog
        title="Rule Editor"
        :visible.sync="dialogVisible"
        append-to-body
        width="60%"
        class="rule-go-json-editor"
        :before-close="handleClose">
        <el-form v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.5)">
            <el-form-item label="Rule JSON">
                <el-input
                    type="textarea"
                    v-model="model.contentText"
                    :autosize="{ minRows: 10 }"
                    class="cereb-textarea"
                    placeholder="input rule json">
                </el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">Cancel</el-button>
            <el-button class="cereb-button" @click="handleConfirm">Confirm</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { Dialog, Form, FormItem } from 'element-ui';
import * as api from '@/api';
export default {
    components: {
        ElDialog: Dialog,
        ElForm: Form,
        ElFormItem: FormItem
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            dialogVisible: this.value,
            loading: false,
            model: {
                contentText: ''
            },
            defaultJson: JSON.stringify({
                "ruleChain": {
                    "id": "chain_call_rest_api",
                    "name": "测试规则链",
                    "debugMode": false,
                    "root": true,
                    "additionalInfo": {
                        "layoutX": "280",
                        "layoutY": "280"
                    }
                },
                "metadata": {
                    "endpoints": [],
                    "nodes": [
                        {
                            "id": "s1",
                            "additionalInfo": {
                                "description": "ddd",
                                "layoutX": 450,
                                "layoutY": 240
                            },
                            "type": "jsFilter",
                            "name": "过滤",
                            "debugMode": true,
                            "configuration": {
                                "jsScript": "return msg!='bb';"
                            }
                        },
                        {
                            "id": "s2",
                            "additionalInfo": {
                                "description": "",
                                "layoutX": 670,
                                "layoutY": 280
                            },
                            "type": "jsTransform",
                            "name": "转换",
                            "debugMode": true,
                            "configuration": {
                                "jsScript": "metadata['test']='test02';\nmetadata['index']=52;\nmsgType='TEST_MSG_TYPE2';\nmsg['aa']=66;\nreturn {'msg':msg,'metadata':metadata,'msgType':msgType};"
                            }
                        },
                        {
                            "id": "s3",
                            "additionalInfo": {
                                "description": "",
                                "layoutX": 930,
                                "layoutY": 190
                            },
                            "type": "restApiCall",
                            "name": "推送数据",
                            "debugMode": true,
                            "configuration": {
                                "headers": {
                                    "Content-Type": "application/json"
                                },
                                "maxParallelRequestsCount": 200,
                                "requestMethod": "POST",
                                "restEndpointUrlPattern": "http://127.0.0.1:9090/api/v1/webhook/test"
                            }
                        },
                        {
                            "id": "node_5",
                            "additionalInfo": {
                                "description": "",
                                "layoutX": 920,
                                "layoutY": 370
                            },
                            "type": "log",
                            "name": "记录错误日志",
                            "debugMode": false,
                            "configuration": {
                                "jsScript": "return 'Incoming message:\\\\n' + JSON.stringify(msg) +\n  '\\\\nIncoming metadata:\\\\n' + JSON.stringify(metadata);"
                            }
                        }
                    ],
                    "connections": [
                        {
                            "fromId": "s1",
                            "toId": "s2",
                            "type": "True"
                        },
                        {
                            "fromId": "s2",
                            "toId": "s3",
                            "type": "Success"
                        },
                        {
                            "fromId": "s2",
                            "toId": "node_5",
                            "type": "Failure"
                        }
                    ]
                }
            })
        }
    },
    watch: {
        value: {
            async handler(val) {
                this.dialogVisible = val;
                await this.init();
            },
            immediate: true
        },
        dialogVisible: {
            handler(val) {
                this.$emit('input', val);
            }
        }
    },
    methods: {
        handleClose() {
            this.$emit('input', false);
        },
        async handleConfirm() {
            await this.submit();
            this.$emit('input', false);
        },
        async submit() {
            this.loading = true;
            try {
                const o = JSON.parse(this.model.contentText)
                const chainId = o.ruleChain.id;
                await api.createRule(o, chainId)
                this.$message({
                    message: 'Save success',
                    type: 'success'
                });
            }
            catch (error) {
                this.$message({
                    message: 'Save failed',
                    type: 'error'
                });
            }
            this.loading = false;
        },
        async fetchData() {
            this.loading = true;
            const o = await api.getRuleById(this.id)
            this.model.contentText = JSON.stringify(o)
            this.loading = false;
        },
        async init() {
            if (this.id) {
                await this.fetchData();
            }
            else {
                this.model.contentText = this.defaultJson;
            }
        }
    },
}
</script>
<style>
.rule-go-json-editor .el-dialog {
    color: #dddddd;
    background: #182130;
}

.rule-go-json-editor .el-dialog .el-dialog__header .el-dialog__title {
    color: #dddddd;
}

.rule-go-json-editor .el-dialog .el-dialog__body {
    color: #cfd1d4;
}
</style>